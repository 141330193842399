import { PageProperty } from './properties/PageProperty'
import { Event } from './Event'
import { ArticleProperty } from './properties/ArticleProperty'

export enum EventNames {
  ARTICLE_VIEW = 'article.view',
}

export interface ArticleViewedEvent extends Event<ArticleViewedEventProperty> {
  name: EventNames.ARTICLE_VIEW
}

export interface ArticleViewedEventProperty {
  article: ArticleProperty
  fromPage: PageProperty
  currentPage: PageProperty
}
