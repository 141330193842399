import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor, getNextPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

export enum UserProfileActionType {
  /**
   * No-op
   */
  USER_HEAD = 'user_head',
  USER_NAME = 'user_name',
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.info.enter-user-profile.md)
 */
export function trackArticleInfoUserProfileEvent(article: Article, actionType: UserProfileActionType) {
  const articleProp = getArticlePropertyFrom(article)
  const authorProfilePath = `/user/${articleProp.authorMid}`
  getEventTracker().track({
    name: WebEventNames.ARTICLE_INFO_ENTER_USER_PROFILE,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.INFO,
      toPage: getNextPagePropertyFor({ pageName: 'user-profile', pageUrl: window.location.origin + authorProfilePath }),
      actionType,
      actionTarget: articleProp.authorMid,
    },
  })
}
