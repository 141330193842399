import { PageComponents, WebEventNames, FollowActionType } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.info.follow.md)
 */
export function trackArticleInfoFollowEvent(article: Article, actionType: FollowActionType) {
  const articleProp = getArticlePropertyFrom(article)
  getEventTracker().track({
    name: WebEventNames.ARTICLE_INFO_FOLLOW,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.INFO,
      actionType,
      actionTarget: articleProp.authorMid,
    },
  })
}
