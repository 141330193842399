import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

export enum ShareToEnum {
  FACEBOOK = 'facebook',
  MESSENGER = 'messenger',
  LINE = 'line',
  INSTAGRAM = 'instagram',
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.share.md)
 */
export function trackArticleShareEvent(article: Article, shareTo: ShareToEnum) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_SHARE,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article'),
      shareTo,
    },
  })
}
