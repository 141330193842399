import { getEventTracker } from '@libs/eventTracking'
import { WebEventNames as EventNames } from './common'
import { ArticleProperty, PageProperty, Hashtag } from '@app-core/eventTracking/properties'
import { Article } from '@app-core/eventTracking/article/Article'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

export type ArticleHashtagEnterSearchEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  toPage: PageProperty
  hashtag: Hashtag
}

export type TrackArticleHashtagEnterSearchEventInput = {
  article: Article
  toPageUrl: string
  hashtag: string
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.hashtag.enter-search.md)
 */
export function trackArticleHashtagEnterSearchEvent(
  input: TrackArticleHashtagEnterSearchEventInput,
) {
  const articleProp = getArticlePropertyFrom(input.article)
  getEventTracker().track<ArticleHashtagEnterSearchEventProperty>({
    name: EventNames.ARTICLE_HASHTAG_ENTER_SEARCH,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      toPage: getNextPagePropertyFor({ pageName: 'search', pageUrl: input.toPageUrl }),
      hashtag: input.hashtag,
    },
  })
}
