import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.copy-link.md)
 */
export function trackArticleCopyLinkEvent(article: Article) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_COPY_LINK,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article'),
    },
  })
}
