import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.read-more-same-author.enter-article.md)
 */
export function trackArticleReadMoreSameAuthorEnterEvent(article: Article, toPath: string) {
  const articleProp = getArticlePropertyFrom(article)
  getEventTracker().track({
    name: WebEventNames.ARTICLE_READ_MORE_SAME_AUTHOR_ENTER,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.READ_MORE_SAME_AUTHOR,
      toPage: getNextPagePropertyFor({
        pageName: 'article',
        pageUrl: window.location.origin + toPath,
      }),
    },
  })
}
