import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/article/article.read-more-recommender.enter-article.md)
 */
export function trackArticleReadMoreRecommenderEnterArticleEvent(
  article: Article,
  toPageUrl: string,
  actionTarget: string,
) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_READ_MORE_RECOMMENDER_ENTER_ARTICLE,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article', null, PageComponents.READ_MORE_RECOMMENDER),
      toPage: getNextPagePropertyFor({ pageName: 'article', pageUrl: toPageUrl }),
      actionTarget,
    },
  })
}
