import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

interface TrackArticleContentEnterLink {
  article: Article
  toPageUrl: string
  buttonText: string
  linkType: 'imageCtaButton'
}

export function trackArticleContentEnterLink({
  article,
  buttonText,
  linkType,
  toPageUrl,
}: TrackArticleContentEnterLink) {
  const articleProp = getArticlePropertyFrom(article)
  getEventTracker().track({
    name: WebEventNames.ARTICLE_CONTENT_ENTER_LINK,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      toPage: getNextPagePropertyFor({
        pageUrl: toPageUrl,
      }),
      buttonText,
      linkType,
    },
  })
}
