import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import {
  getArticleCategory,
  getArticleCheckIn,
  getArticleImageSize,
  getArticleMid,
  getArticlePostId,
  getArticleTitle,
  getArticleUid,
} from '@app-core/eventTracking/article/articleUtil'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/d824ehq1lidfy0ign5hv8/.paper?dl=0&rlkey=cxrexqt4t2ehtd1kyzrtygxcu)
 */
export function trackAppPromotionBarDownloadEvent(href, articlePostData) {
  const {
    editorType: articleEditorType,
    authorCurrentRoles,
    contentType: articleContentType,
    authorRoles,
    tags: articleTags,
  } = articlePostData

  const currentPage = getCurrentPagePropertyFor('article', window.location.href)
  const toPage = getNextPagePropertyFor({ pageUrl: href })
  const article = {
    articleId: getArticlePostId(articlePostData),
    articleTitle: getArticleTitle(articlePostData),
    articleCategory: getArticleCategory(articlePostData),
    articleEditorType,
    articleCheckin: getArticleCheckIn(articlePostData),
    articleContentType,
    articleImageNum: getArticleImageSize(articlePostData),
    authorUid: getArticleUid(articlePostData),
    authorMid: getArticleMid(articlePostData),
    authorRoles,
    authorCurrentRoles,
    articleTags,
  }

  getEventTracker().track({
    name: WebEventNames.ARTICLE_APP_PROMOTION_BAR_DOWNLOAD,
    properties: {
      currentPage,
      toPage,
      article,
    },
  })
}
