import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor, getNextPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.copy-link.md)
 */
export function trackArticleEnterEditorEvent(article: Article, toPath: string) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_ENTER_EDITOR,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article'),
      toPage: getNextPagePropertyFor({ pageName: 'editor', pageUrl: window.location.origin + toPath })
    },
  })
}
