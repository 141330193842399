import { CommonEventNames as EventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { createTrackArticleFromArticle } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getCampaignProperty,
  getFromPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export interface TrackArticleViewEventInput {
  article: Article
  fromPage?: any
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.view.md)
 */
export function trackArticleViewEvent(input: TrackArticleViewEventInput): void {
  const article = createTrackArticleFromArticle(input.article)
  getEventTracker().track({
    name: EventNames.ARTICLE_VIEW,
    properties: omitBy(
      {
        article,
        currentPage: getCurrentPagePropertyFor('article'),
        fromPage: getFromPagePropertyFor(input.fromPage),
        utm: getCampaignProperty(),
      },
      isNil,
    ),
  })
}
