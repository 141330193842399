import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.info.enter-place.md)
 */
export function trackArticleInfoEnterPlaceEvent(article: Article, toPath = '') {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_INFO_ENTER_PLACE,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.INFO,
      toPage: getNextPagePropertyFor({
        pageName: 'place',
        pageUrl: window.location.origin + toPath,
      }),
    },
  })
}
