import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/article/article.read-more-popular-list.enter-article.md)
 */

interface TrackArticleInput {
  article: Article
  toPageUrl: string
  actionTarget: string
}
export function trackArticleReadMorePopularListEnterArticleEvent({
  article,
  toPageUrl,
  actionTarget,
}: TrackArticleInput) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_READ_MORE_POPULAR_LIST_ENTER_ARTICLE,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor(
        'article',
        null,
        PageComponents.READ_MORE_POPULAR_LIST,
      ),
      toPage: getNextPagePropertyFor({ pageName: 'article', pageUrl: toPageUrl }),
      actionTarget,
    },
  })
}
