import { PageComponents, WebEventNames, LikeActionType } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.read-more-same-type.like.md)
 */
export function trackArticleReadMoreSameAuthorLikeEvent(
  article: Article,
  actionType: LikeActionType,
) {
  const articleProp = getArticlePropertyFrom(article)
  getEventTracker().track({
    name: WebEventNames.ARTICLE_READ_MORE_SAME_AUTHOR_LIKE,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.READ_MORE_SAME_AUTHOR,
      actionType,
      actionTarget: articleProp.articleId,
    },
  })
}
