import { PageComponents, WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/1fbfcc4ac9e0f77ed3d66f264d381c645b0336d7/tracking_events/events/article/article.tag.enter-web-tag.md)
 */

interface TrackArticleTagEnterWebTagEventInput {
  article: Article
  tag: string
  toPageUrl: string
}

export function trackArticleTagEnterWebTagEvent(input: TrackArticleTagEnterWebTagEventInput) {
  const { article, tag, toPageUrl } = input

  getEventTracker().track({
    name: WebEventNames.ARTICLE_TAG_ENTER_WEB_TAG,
    properties: {
      article: getArticlePropertyFrom(article),
      currentPage: getCurrentPagePropertyFor('article', null, PageComponents.TAG),
      toPage: getNextPagePropertyFor({ pageName: 'web-tag', pageUrl: toPageUrl }),
      actionTarget: tag,
    },
  })
}
