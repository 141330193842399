import { getEventTracker } from '@libs/eventTracking'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from '.'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/d824ehq1lidfy0ign5hv8/.paper?dl=0&rlkey=cxrexqt4t2ehtd1kyzrtygxcu)
 */
export function trackBottomAppPromotionBannerEvent(currentPageUrl, slogan) {
  getEventTracker().track({
    name: WebEventNames.ARTICLE_BOTTOM_APP_PROMOTION_BANNER_DOWNLOAD,
    properties: {
      currentPage: getCurrentPagePropertyFor('article', currentPageUrl),
      slogan,
    },
  })
}
