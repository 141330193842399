import { ArticleProperty } from '../properties/ArticleProperty'
import { Article, ImageInfo } from './Article'

export function getArticlePostId(article: Article) {
  return article?.postID || ''
}

export function getArticleType(article: Article) {
  return article?.type || 'unknown'
}

function isUserArticleType(article: Article) {
  const isUserArticle = getArticleType(article) === 'user'
  return isUserArticle
}

export function getArticleTitle(article: Article) {
  const title = article?.title || ''
  const webTitle = article?.web?.title || ''
  return (isUserArticleType(article) ? title : webTitle).replace(/\n/g, '')
}

export function getArticlePath(article: Article) {
  const path = getArticlePostId(article).split('.').join('/')
  return path
}

export function getArticleCategory(article: Article) {
  const category = article?.clas[0] || article?.web?.clas[0] || 'unknown'
  return category
}

export function getArticleCheckIn(article: Article) {
  return article?.loc || ''
}

export function getArticleUid(article: Article) {
  return article?.uid || article?.web?.user?.uid || ''
}

export function getArticleImageSize(article: Article) {
  const imgArray: ImageInfo[] = article?.img
  return imgArray?.length || 0
}

export function getArticleMid(article: Article) {
  return article?.userInfo?.mid || ''
}

export function getArticleRoles(article: Article) {
  return article?.authorRoles
}

export function getArticleCurrentRoles(article: Article) {
  return article?.authorCurrentRoles
}

export function createTrackArticleFromArticle(article: Article) {
  const trackArticle: ArticleProperty = {
    articleId: getArticlePostId(article),
    articleTitle: getArticleTitle(article),
    articleCategory: getArticleCategory(article),
    articleEditorType: article?.editorType,
    articleCheckin: getArticleCheckIn(article),
    articleImageNum: getArticleImageSize(article),
    articleContentType: article?.contentType,
    authorUid: getArticleUid(article),
    authorMid: getArticleMid(article),
    authorRoles: getArticleRoles(article),
    authorCurrentRoles: getArticleCurrentRoles(article),
    articleTags: article?.tags,
  }

  return trackArticle
}

export const getArticlePropertyFrom = createTrackArticleFromArticle
