import { WebEventNames } from './common'
import { getEventTracker } from '@libs/eventTracking'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { Article } from '@app-core/eventTracking/article/Article'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.author-info.enter-user-profile.md)
 */
export function trackArticleAuthorInfoEnterUserProfileEvent(article: Article) {
  const articleProp = getArticlePropertyFrom(article)
  const authorProfilePath = `/user/${articleProp.authorMid}`
  getEventTracker().track({
    name: WebEventNames.ARTICLE_AUTHOR_INFO_ENTER_USER_PROFILE,
    properties: {
      currentPage: getCurrentPagePropertyFor('article'),
      toPage: getNextPagePropertyFor({
        pageName: 'user-profile',
        pageUrl: window.location.origin + authorProfilePath,
      }),
      actionTarget: articleProp.authorMid,
    },
  })
}
