import { getEventTracker } from '@libs/eventTracking'
import { WebEventNames as EventNames, PageComponents, LikeActionType } from './common'
// prettier-ignore
import { ArticleProperty, PageProperty, CommentId, ArticleId, ReplyId } from '@app-core/eventTracking/properties'
import { Article } from '@app-core/eventTracking/article/Article'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

export type ArticleCommentLikeEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  pageComponent: PageComponents.COMMENT
  actionType: LikeActionType
  actionTarget: CommentId
}

export type TrackArticleCommentLikeEventInput = {
  article: Article
  actionType: LikeActionType
  commentId: CommentId
  pageUrl: string
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.comment.like.md)
 */
export function trackArticleCommentLikeEvent(input: TrackArticleCommentLikeEventInput) {
  const articleProp = getArticlePropertyFrom(input.article)
  getEventTracker().track<ArticleCommentLikeEventProperty>({
    name: EventNames.ARTICLE_COMMENT_LIKE,
    properties: {
      article: articleProp,
      currentPage: {
        pageName: 'article',
        pageUrl: input.pageUrl,
        region: 'Taiwan',
      },
      pageComponent: PageComponents.COMMENT,
      actionType: input.actionType,
      actionTarget: input.commentId,
    },
  })
}

export type ArticleCommentReplyFinishEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  pageComponent: PageComponents.COMMENT
  actionTarget: ArticleId | ReplyId
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.comment.reply.finish.md)
 */
export function trackArticleCommentReplyFinishEvent(
  articleInput: Article,
  actionTarget: ArticleId | ReplyId,
) {
  const articleProp = getArticlePropertyFrom(articleInput)
  getEventTracker().track<ArticleCommentReplyFinishEventProperty>({
    name: EventNames.ARTICLE_COMMENT_REPLY_FINISH,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.COMMENT,
      actionTarget,
    },
  })
}
