export { EventNames as CommonEventNames } from '@app-core/eventTracking/ArticleEvents'

export enum WebEventNames {
  PGC_ARTICLE_LIST_VIEW = 'pgc-article-list.view',
  ARTICLE_BOTTOM_ANCHOR_LIKE = 'article.bottom-anchor.like',
  ARTICLE_BOTTOM_ANCHOR_SAVE = 'article.bottom-anchor.save',
  ARTICLE_BOTTOM_ANCHOR_REPLY_FINISH = 'article.bottom-anchor.reply.finish',
  ARTICLE_COMMENT_LIKE = 'article.comment.like',
  ARTICLE_COMMENT_REPLY_FINISH = 'article.comment.reply.finish',
  ARTICLE_HASHTAG_ENTER_SEARCH = 'article.hashtag.enter-search',
  ARTICLE_COPY_LINK = 'article.copy-link',
  ARTICLE_REPORT = 'article.report',
  ARTICLE_SHARE = 'article.share',
  ARTICLE_ENTER_EDITOR = 'article.enter-editor',
  ARTICLE_DELETE = 'article.delete',
  ARTICLE_INFO_ENTER_PLACE = 'article.info.enter-place',
  ARTICLE_INFO_ENTER_USER_PROFILE = 'article.info.enter-user-profile',
  ARTICLE_AUTHOR_INFO_ENTER_USER_PROFILE = 'article.author-info.enter-user-profile',
  ARTICLE_INFO_FOLLOW = 'article.info.follow',
  ARTICLE_AUTHOR_INFO_FOLLOW = 'article.author-info.follow',
  ARTICLE_READ_MORE_SAME_TYPE_LIKE = 'article.read-more-same-type.like',
  ARTICLE_READ_MORE_SAME_AUTHOR_ENTER = 'article.read-more-same-author.enter-article',
  ARTICLE_READ_MORE_SAME_TYPE_ENTER = 'article.read-more-same-type.enter-article',
  ARTICLE_READ_MORE_SAME_AUTHOR_LIKE = 'article.read-more-same-author.like',
  ARTICLE_CONTENT_OPEN_PAZZO_ITEM_MENU = 'article.content.open-pazzo-item-menu',
  ARTICLE_READ_MORE_RECOMMENDER_ENTER_ARTICLE = 'article.read-more-recommender.enter-article',
  ARTICLE_READ_MORE_POPULAR_LIST_ENTER_ARTICLE = 'article.read-more-popular-list.enter-article',
  ARTICLE_TAG_ENTER_WEB_TAG = 'article.tag.enter-web-tag',
  ARTICLE_CONTENT_ENTER_ARTICLE = 'article.content.enter-article',
  ARTICLE_APP_PROMOTION_BAR_DOWNLOAD = 'article.app-promotion-bar.download',
  ARTICLE_BOTTOM_APP_PROMOTION_BANNER_DOWNLOAD = 'article.bottom-app-promotion-banner.download',
  ARTICLE_CONTENT_ENTER_LINK = 'article.content.enter-link',
}

export enum PageComponents {
  BOTTOM_ANCHOR = 'bottom-anchor',
  COMMENT = 'comment',
  INFO = 'info',
  READ_MORE_SAME_TYPE = 'read-more-same-type',
  READ_MORE_SAME_AUTHOR = 'read-more-same-author',
  MAIN = 'main',
  READ_MORE_RECOMMENDER = 'read-more-recommender',
  READ_MORE_POPULAR_LIST = 'read-more-popular-list',
  TAG = 'tag',
  RECOMMENDER_CARD = 'recommender-card',
}

export enum LikeActionType {
  LIKE = 'like',
  UNLIKE = 'unlike',
}

export enum FollowActionType {
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
}
