import { getEventTracker } from '@libs/eventTracking'
import { WebEventNames as EventNames, PageComponents, LikeActionType } from './common'
// prettier-ignore
import { ArticleProperty, PageProperty, ArticleId, TopicId } from '@app-core/eventTracking/properties'
import { Article } from '@app-core/eventTracking/article/Article'
import { getArticlePropertyFrom } from '@app-core/eventTracking/article/articleUtil'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

export type ArticleBottomAnchorLikeEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  pageComponent: PageComponents.BOTTOM_ANCHOR
  actionType: LikeActionType
  actionTarget: ArticleId
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.bottom-anchor.like.md)
 */
export function trackArticleBottomAnchorLikeEvent(
  articleInput: Article,
  actionType: LikeActionType,
) {
  const articleProp = getArticlePropertyFrom(articleInput)
  getEventTracker().track<ArticleBottomAnchorLikeEventProperty>({
    name: EventNames.ARTICLE_BOTTOM_ANCHOR_LIKE,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.BOTTOM_ANCHOR,
      actionType,
      actionTarget: articleProp.articleId, // 在這邊都是指同一篇文章 actionTarget 可以直接拿 articleId 來用
    },
  })
}

export enum SaveActionType {
  SAVE = 'save',
  UNSAVE = 'unsave',
}

export type ArticleBottomAnchorSaveEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  pageComponent: PageComponents.BOTTOM_ANCHOR
  actionType: SaveActionType
  actionTarget: ArticleId
  saveTo: TopicId
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.bottom-anchor.save.md)
 */
export function trackArticleBottomAnchorSaveEvent(
  articleInput: Article,
  actionType: SaveActionType,
  topicId: TopicId,
) {
  const articleProp = getArticlePropertyFrom(articleInput)
  getEventTracker().track<ArticleBottomAnchorSaveEventProperty>({
    name: EventNames.ARTICLE_BOTTOM_ANCHOR_SAVE,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.BOTTOM_ANCHOR,
      actionType,
      actionTarget: articleProp.articleId, // 在這邊都是指同一篇文章 actionTarget 可以直接拿 articleId 來用
      saveTo: topicId,
    },
  })
}

export type ArticleBottomAnchorReplyFinishEventProperty = {
  article: ArticleProperty
  currentPage: PageProperty
  pageComponent: PageComponents.BOTTOM_ANCHOR
  actionTarget: ArticleId
}

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/amplitude/events/article/article.bottom-anchor.reply.finish.md)
 */
export function trackArticleBottomAnchorReplyFinishEvent(articleInput: Article) {
  const articleProp = getArticlePropertyFrom(articleInput)
  getEventTracker().track<ArticleBottomAnchorReplyFinishEventProperty>({
    name: EventNames.ARTICLE_BOTTOM_ANCHOR_REPLY_FINISH,
    properties: {
      article: articleProp,
      currentPage: getCurrentPagePropertyFor('article'),
      pageComponent: PageComponents.BOTTOM_ANCHOR,
      actionTarget: articleProp.articleId, // 在這邊都是指同一篇文章 actionTarget 可以直接拿 articleId 來用
    },
  })
}
